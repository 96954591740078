document.addEventListener('DOMContentLoaded', function () {
  /***************  galerie image   *************/
  const galerie_cards = document.querySelector('.galerie_cards');
  const arrowLeft = document.querySelector('.arrow-left');
  const arrowRight = document.querySelector('.arrow-right');

  let currentPosition = 0;
  let cardWidth; // La largeur de la carte sera définie dynamiquement

  function updateCardWidth() {
    if (window.innerWidth <= 563) {
      cardWidth = 15; 
    } else {
      cardWidth = 22.563; 
    }

    // Mettre à jour la position actuelle en conséquence
    galerie_cards.style.transform = `translateX(-${currentPosition * cardWidth}rem)`;
  }

  // Appeler la fonction pour définir la largeur initiale
  updateCardWidth();

  function scrollLeft() {
    if (currentPosition > 0) {
      currentPosition -= 1;
    } else {
      currentPosition = galerie_cards.children.length - 1;
    }
    galerie_cards.style.transform = `translateX(-${currentPosition * cardWidth}rem)`;
  }

  function scrollRight() {
    if (currentPosition < galerie_cards.children.length - 1) {
      currentPosition += 1;
    } else {
      currentPosition = 0;
    }
    galerie_cards.style.transform = `translateX(-${currentPosition * cardWidth}rem)`;
  }

  // Défilement automatique toutes les 3 secondes (3000 millisecondes)
  setInterval(scrollRight, 3000);
  arrowLeft.addEventListener('click', scrollLeft);
  arrowRight.addEventListener('click', scrollRight);

  // Mettre à jour la largeur de la carte lors du redimensionnement de la fenêtre
  window.addEventListener('resize', updateCardWidth);

  /*const galerie_cards = document.querySelector('.galerie_cards');
  const arrowLeft = document.querySelector('.arrow-left');
  const arrowRight = document.querySelector('.arrow-right');

  let currentPosition = 0;
  const cardWidth = 22.563;

  function scrollLeft() {
    if (currentPosition > 0) {
      currentPosition -= 1;
    } else {
      currentPosition = galerie_cards.children.length - 1;
    }
    galerie_cards.style.transform = `translateX(-${
      currentPosition * cardWidth
    }rem)`;
  }

  function scrollRight() {
    if (currentPosition < galerie_cards.children.length - 1) {
      currentPosition += 1;
    } else {
      currentPosition = 0;
    }
    galerie_cards.style.transform = `translateX(-${
      currentPosition * cardWidth
    }rem)`;
  }

  setInterval(scrollRight, 3000);
  arrowLeft.addEventListener('click', scrollLeft);
  arrowRight.addEventListener('click', scrollRight);*/

  /**********   menu version mobile  ***********/

  /*******************animation scroll   *****************/
  
  const main = document.querySelector('.container');
  console.log(main);
  const isHomePage = main.classList.contains('home');
  console.log(isHomePage);
  if (isHomePage) {
    console.log("animation scroll");
    const elements = document.querySelectorAll('.not-visible');
    
    function checkScroll() {
      const triggerBottom = window.innerHeight * 0.8;
      let highestZIndex = 100; // Initialisez avec la valeur du z-index de votre premier élément
      elements.forEach((element) => {
          const elementTop = element.getBoundingClientRect().top;
  
          if (elementTop < triggerBottom) {
              element.classList.add('visible');
              element.classList.remove('not-visible');
              element.style.zIndex = highestZIndex; 
               // Vérifiez si l'élément a déjà une propriété position
            if (getComputedStyle(element).position === 'static') {
              element.style.position = 'relative'; // Ajoutez la propriété position: relative;
          }
              highestZIndex--; 
          }
      });
    }
  
    window.addEventListener('scroll', checkScroll);
    checkScroll();
  }
  
  const customSummaries = document.querySelectorAll('.custom-summary');
  customSummaries[4].style.display = 'none';
  console.log(customSummaries);

  const assistanceLink = document.querySelector('#menu-item-2796 a');

// Check if the element exists before modifying it
if (assistanceLink) {
    assistanceLink.setAttribute('target', '_blank');
}

});
